// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-insights-strapi-category-slug-js": () => import("./../../../src/pages/insights/{StrapiCategory.slug}.js" /* webpackChunkName: "component---src-pages-insights-strapi-category-slug-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-casestudy-js": () => import("./../../../src/templates/casestudy.js" /* webpackChunkName: "component---src-templates-casestudy-js" */),
  "component---src-templates-devops-js": () => import("./../../../src/templates/devops.js" /* webpackChunkName: "component---src-templates-devops-js" */),
  "component---src-templates-insights-js": () => import("./../../../src/templates/insights.js" /* webpackChunkName: "component---src-templates-insights-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-preview-js": () => import("./../../../src/templates/preview.js" /* webpackChunkName: "component---src-templates-preview-js" */)
}

