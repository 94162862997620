/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { CookiesProvider } from 'react-cookie';
import "@fontsource/roboto"
import "@fontsource/roboto/300-italic.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/900.css";
import "./src/styles/normalize.css"
import "./src/styles/global.css"
import "./src/styles/scss/main.scss"

export const wrapRootElement = ({ element }) => (
    <CookiesProvider>{element}</CookiesProvider>
)
